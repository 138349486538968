<template>
  <div class="mt-3">
    <!--Alert-->
    <CustomerPaymentsReconcileAlert
      :type="type"
      v-if="amountLeftToAllocate === 0"
    />
    <!--Summary of totals-->
    <v-row>
      <v-col lg="6" md="6">
        <p>
          Transaction amount:
          <span class="float-right">{{
            formatAsCurrency("R", transaction.transaction_amount)
          }}</span>
        </p>
        <p>
          Amount allocated:
          <span class="float-right">{{
            formatAsCurrency("R", totalAmountAllocated)
          }}</span>
        </p>
        <p>
          Amount left to allocate:
          <span :class="formatClass">{{
            formatAsCurrency("R", amountLeftToAllocate)
          }}</span>
        </p>
        <p v-if="overpaidAmount !== 0">
          Overpayment amount:
          <span :class="formatClass"
            >{{ formatAsCurrency("R", overpaidAmount) }}
            <!---->
            <v-btn
              outlined
              x-small
              right
              class="absolute-button"
              @click="$emit('removeOverpaid')"
              >Remove</v-btn
            >
          </span>
        </p>
      </v-col>
    </v-row>
    <!--Divider-->
    <v-row class="mb-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { formatAsCurrency } from "../../../../../composables/external";
export default {
  name: "CustomerPaymentTotals",
  props: [
    "transaction",
    "totalAmountAllocated",
    "amountLeftToAllocate",
    "overpaidAmount",
    "type",
  ],
  components: {
    CustomerPaymentsReconcileAlert: () =>
      import("./CustomerPaymentsReconcileAlert.vue"),
  },
  data() {
    return {};
  },
  computed: {
    formatClass() {
      return this.amountLeftToAllocate === 0
        ? "primary--text float-right"
        : "float-right";
    },
  },
  methods: {
    formatAsCurrency,
  },
};
</script>
<style scoped>
.absolute-button {
  position: absolute;
  margin-left: 10px;
  margin-top: 1px;
}
.absolute-button:hover {
  color: red;
}
</style>